<template>
  <header :class="headerClass">
      <div class="container">
          <a href="#" id="logo"><img :src="require('@/assets/logo-header.png')" alt="vorkum" class="img-fluid"></a>
          <div id="lang_picker">
            <a v-show="locale == 'si'" @click="changeLocale('en')"><img :src="require('@/assets/en.png')" alt="en" class="img-fluid"></a>
            <a v-show="locale == 'en'" @click="changeLocale('si')"><img :src="require('@/assets/si.png')" alt="si" class="img-fluid"></a>
          </div>
          <nav id="main_nav">
            <router-link :to="`/${locale}/#what-we-do`">{{ t('What we do') }}</router-link>
            <router-link :to="`/${locale}/#case-studies`">{{ t('Case studies') }}</router-link>
            <router-link :to="`/${locale}/#testimonials`">{{ t('Testimonials') }}</router-link>
            <router-link :to="`/${locale}/#contact`">{{ t('Contact us') }}</router-link>
          </nav>
      </div>
  </header>
</template>

<script>
import { ref } from "vue";
import { useI18n } from 'vue-i18n'
import router from "@/router"

export default {
  setup() {

    const headerClass = ref(null)

    const updateScroll = () => {
      headerClass.value = window.scrollY > 30 ? "active" : ""
    }

    window.addEventListener('scroll', updateScroll);

    const { t, locale, availableLocales } = useI18n({ useScope: 'global' })

    const changeLocale = (lang) => {
      locale.value = lang
      router.push("/"+lang)
    }

    return {
      headerClass,
      availableLocales,
      t,
      locale,
      changeLocale
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
