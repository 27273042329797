<template>
  <section id="case-studies">
    <div class="container-fluid">
      <div class="heading">
        <h2 class="title">{{ t("Case studies") }}</h2>
        <div class="mini-separator center pink"></div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6" v-for="(sc,scKey) in showcases" :key="scKey">
          <div class="showcase_wrapper">
            <a :href="sc.url" class="image" target="_blank">
              <img :src="require('@/assets/showcase/'+sc.logo)" :alt="sc.name" class="img-fluid">
            </a>
            <a :href="sc.url" class="textbox" target="_blank">
              <span class="title">{{sc.name}}</span>
              <span class="type">{{t(sc.type)}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n({
      messages: {
        "en": {
          "Website and Webstore": "Website and Webstore",
          "Webstore": "Webstore",
          "Website": "Website",
          "Culinary Blog" : "Culinary Blog",
          "Landing Page": "Landing Page"
        },
        "si": {
          "Website and Webstore": "Spletna stran in spletna trgovina",
          "Webstore": "Spletna trgovina",
          "Website": "Spletna stran",
          "Culinary Blog" : "Kulinarični blog",
          "Landing Page": "Pristajalna stran"
        }
      }
    })

    const showcases = [
      {
        logo: 'srecanavrvici.png',
        url: 'https://srecanavrvici.si/',
        name: 'Sreča na vrvici',
        type: "Website"
      },
      {
        logo: 'czrdomzale.png',
        url: 'https://www.czrdomzale.si/',
        name: 'CZR Domžale',
        type: "Website"
      },
      {
        logo: 'popikoki.png',
        url: 'https://www.popikoki.si',
        name: 'Popikoki',
        type: "Webstore"
      },
      {
        logo: 'aran.png',
        url: 'http://aran.si/',
        name: 'Aran kuhinje',
        type: "Website and Webstore"
      },
      {
        logo: 'centerkontura.png',
        url: 'https://www.centerkontura.si/',
        name: 'CenterKontura',
        type: "Website and Webstore"
      },
      {
        logo: 'nkradomlje.png',
        url: 'https://www.nk-radomlje.si/',
        name: 'NK Radomlje',
        type: "Website and Webstore"
      },
      {
        logo: 'anzezupan.png',
        url: 'https://www.a-z.si/',
        name: 'Anže Zupan - osebni trener',
        type: "Website and Webstore"
      },
      {
        logo: 'lepaafna.png',
        url: 'https://lepaafna.si/',
        name: 'Lepa Afna',
        type: "Website and Webstore"
      },
      {
        logo: 'winedineslovenia.png',
        url: 'https://www.winedineslovenia.com/',
        name: 'Wine Dine Slovenia',
        type: "Culinary Blog"
      },
      {
        logo: 'pdkamnik.png',
        url: 'https://pdkamnik.si/',
        name: 'Planinsko društvo Kamnik',
        type: "Website"
      },
      {
        logo: 'jamesonband.png',
        url: 'http://jameson-band.com/',
        name: 'Jameson Band',
        type: "Landing Page"
      },
      {
        logo: 'aokamnik.png',
        url: 'https://aokamnik.si/',
        name: 'Alpinistični odsek Kamnik',
        type: "Website"
      },
    ];

    return {
      t,
      showcases
    }
  }
}

</script>
