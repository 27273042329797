import { createApp } from 'vue/dist/vue.esm-bundler';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueGtag from "vue-gtag";

createApp(App)
.use(router)
.use(i18n)
.use(store)
.use(VueGtag, {
  config: { id: "UA-11441339-6" }
})
.mount('#app')
