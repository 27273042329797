import i18n from '../i18n'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/:locale',
    beforeEnter: (to, from, next) => { // <------------
        const locale = to.params.locale; // 1
        const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
        if (!supported_locales.includes(locale)) return next('en'); // 3
        if (i18n.global.locale.value !== locale) { // 4
          i18n.global.locale.value = locale;
        }
        let meta_desc_tag = document.createElement('meta');
        meta_desc_tag.setAttribute('property', 'description');
        if (locale === "en") {
          document.title = "vorkum | web agency"
          meta_desc_tag.content = "website development, ecommerce development, web development";
        } else {
          document.title = "vorkum | načrtovanje in razvoj spletnih aplikacij"
          meta_desc_tag.content = "izdelava spletnih strani, izdelava spletnih trgovin, izdelava spletnih aplikacij, spletne aplikacije po meri";
        }
        document.getElementsByTagName('head')[0].appendChild(meta_desc_tag);
        return next() // 5
      },
    component: {
      template: "<router-view></router-view>"
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
      // {
      //   path: '/about',
      //   name: 'about',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      // }
    ]
  },
  { // <---------------
    path: '',
    redirect() {
      return process.env.VUE_APP_I18N_LOCALE;
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
        let _hash = to.hash.replace("#","")
        // document.getElementById(_hash).scrollIntoView({block: 'center', behavior: 'smooth'});
        window.scroll(0,document.getElementById(_hash).offsetTop - 70);
    }
  }
})

export default router
