<template>
  <section id="tools">
    <div class="container">
      <div class="heading">
          <h2 class="title">{{ t("Tools and tehnologies")}} </h2>
          <div class="mini-separator center pink"></div>
        </div>
        <div class="tools_list">
        <div class="tool_logo" v-for="(t,toolKey) in tools" :key="toolKey">
          <a :href="t.url" target="blank">
            <img :src="require('@/assets/'+t.logo)" :alt="t.name" class="img-fluid">
          </a>
        </div>
      </div>
      <div class="tools_list">
        <div class="tool_logo" v-for="(t,toolKey) in tools2" :key="toolKey">
          <a :href="t.url" target="blank">
            <img :src="require('@/assets/'+t.logo)" :alt="t.name" class="img-fluid">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    const tools = [
      {
        logo: 'prestashop.png',
        url: 'http://www.prestashop.com',
        name: 'Prestashop'
      },
      {
        logo: 'wordpress.png',
        url: 'http://www.wordpress.org',
        name: 'Wordpress'
      },
      {
        logo: 'woocommerce-logo.png',
        url: 'https://www.woothemes.com/woocommerce/',
        name: 'Woocommerce'
      },
      {
        logo: 'codeigniter-logo.png',
        url: 'https://www.codeigniter.com/',
        name: 'Codeigniter'
      },
      {
        logo: 'logo-php.png',
        url: 'http://php.net/',
        name: 'PHP'
      },
      {
        logo: 'logo-mysql.png',
        url: 'https://www.mysql.com/',
        name: 'MySql'
      },


    ];
    const tools2 = [
      {
        logo: 'sass-less.png',
        url: 'http://lesscss.org/',
        name: 'Less'
      },
      {
        logo: 'git.png',
        url: 'https://git-scm.com/',
        name: 'Git'
      },
      {
        logo: 'jquery.png',
        url: 'https://jquery.com/',
        name: 'jQuery'
      },
      {
        logo: 'laravel.png',
        url: 'https://laravel.com/',
        name: 'Laravel'
      },
      {
        logo: 'vuejs.png',
        url: 'https://vuejs.org',
        name: 'Vue.js'
      },
      {
        logo: 'smarty-logo.png',
        url: 'http://www.smarty.net/',
        name: 'Smarty'
      }
    ];

    return {
      t,
      tools,
      tools2
    }
  }
}

</script>
