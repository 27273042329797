<template>
  <IntroHero />
  <WhatWeDo />
  <QualitySection />
  <CaseStudies />
  <TestimonialsList />
  <PartnersList />
  <ToolsList />
  <ContactUs />
</template>

<script>
import IntroHero from "@/components/sections/IntroHero.vue"
import WhatWeDo from "@/components/sections/WhatWeDo.vue"
import QualitySection from "@/components/sections/QualitySection.vue"
import CaseStudies from "@/components/sections/CaseStudies.vue"
import TestimonialsList from "@/components/sections/TestimonialsList.vue"
import PartnersList from "@/components/sections/PartnersList.vue"
import ToolsList from "@/components/sections/ToolsList.vue"
import ContactUs from "@/components/sections/ContactUs.vue"

export default {
  name: 'HomeView',
  components: {
    IntroHero,
    WhatWeDo,
    QualitySection,
    CaseStudies,
    TestimonialsList,
    PartnersList,
    ToolsList,
    ContactUs
  }
}
</script>

<style>

</style>
