<template>
  <PageHeader />
  <router-view/>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue"

export default {
  components: {
    PageHeader
  },
  setup() {

  }
}
</script>

<style lang="scss">
@import "@/styles/main.scss"
</style>
