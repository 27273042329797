<template>
  <section class="" id="quality">
      <div class="container">
        <div class="heading">
          <h2 class="title">{{ t("Good cooperation guarantees a good product.") }}</h2>
          <div class="mini-separator center"></div>
        </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="wrapper">
                      <div class="logocontainer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-headphones" viewBox="0 0 16 16">
                         <path d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z"/>
                        </svg>
                      </div>
                    <h4 class="title">{{ t("We listen to our clients.") }}</h4>
                    <div class="description">
                    <p> {{ t("Tell us your wishes, the purpose of the website and what you want to achieve. It is our job to listen and shape your goals into a design that heightens the meaning by the content.") }}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
              <div class="wrapper animated">
                      <div class="logocontainer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-triangle-fill" viewBox="0 0 16 16">
                         <path fill-rule="evenodd" d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"/>
                        </svg>
                      </div>
                    <h4 class="title">{{ t("Good, fast or low-cost. Choose two.") }}</h4>
                    <div class="description">
                    <p>{{ t("A project triangle, identified by volume, time and costs, can help us find the best solution. We strive for a quality, optimum solution, and a fast completion.") }}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
              <div class="wrapper animated">
                      <div class="logocontainer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                      </div>
                    <h4 class="title">{{ t("Honesty reflects respect for clients.") }}</h4>
                    <div class="description">
                    <p>{{ t("Mutual respect and honesty are also important for good cooperation. We are honest to all our clients, about both the work and our cooperation. It is a reflection of our respect.") }}</p>
                    </div>
                </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
}

</script>

<style lang="css" scoped>
</style>
