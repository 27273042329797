<template>
  <section id="what-we-do">
    <div class="container">
      <div class="heading">
          <h2 class="title">{{ t('We are experts in...') }}</h2>
          <div class="mini-separator center pink"></div>
        </div>
        <ul>
          <li v-for="(message,messageKey) in getLocaleMessage(locale)" :key="messageKey" class="">{{ message }}</li>
        </ul>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t, getLocaleMessage, locale } = useI18n({
      messages: {
        "en": {
          "website development": "website development",
          "ecommerce website development":"ecommerce website development",
          "extending and adapting opensource software": "extending and adapting opensource software",
          "building advanced web and mobile applications": "building advanced web and mobile applications",
          "website and webstore maintanance": "website and webstore maintanance",
          "wordpress and woocommerce custom programming": "Wordpress and Woocommerce custom programming",
          "prestashop development": "Prestashop development"
        },
        "si": {
          "website development": "izdelave spletih strani",
          "ecommerce website development":"izdelave spletnih trgovin",
          "extending and adapting opensource software": "prilagajanja odprtokodnih spletnih aplikacij in rešitev",
          "building advanced web and mobile applications": "načrtovanja in razvoja naprednih in kompleksnih spletnih ter mobilnih aplikacij",
          "website and webstore maintanance": "vzdrževanja spletnih strani in spletnih trgovin",
          "wordpress and woocommerce custom programming": "razvoja vtičnikov po meri za platformo Wordpress in Woocommerce",
          "prestashop development": "za platformo Prestashop"
        }
      }
    })

    return {
      t, locale, getLocaleMessage
    }
  }
}

</script>

<style lang="css" scoped>
</style>

<i18n>

</i18n>
