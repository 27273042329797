<template>
  <section id="partners">
    <div class="container">
      <div class="heading">
          <h2 class="title">{{ t("Our partners") }}</h2>
          <div class="mini-separator center pink"></div>
        </div>
        <div class="partners_list">
          <div class="partner" v-for="(partner,partnerKey) in partners" :key="partnerKey">
            <a :href="partner.url" target="blank">
              <img :src="require('@/assets/'+partner.logo)" alt="" class="img-fluid">
            </a>
          </div>
        </div>

    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    const partners = [
      {
        logo: "sinusiks.png",
        url: 'http://www.sinusiks.com'
      }, {
        logo: 'cnj_w_label.png',
        url: 'http://www.cnj.si'
      }, {
        logo: 'du.png',
        url: 'http://www.dejanulcej.com'
      }, {
        logo: 'storeez.png',
        url: 'https://storeezstudio.com'
      }
    ];

    return {
      t,
      partners
    }
  }
}

</script>
