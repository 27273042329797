<template lang="html">
  <section id="intro">
    <div class="text_wrapper">
      <img :src="require('@/assets/logo.png')" alt="vorkum" class="img-fluid animate__animated animate__rotateIn">
      <h1>
        <span class="animate__animated animate__zoomIn">{{ t('Simplicity') }}.</span>
        <span class="animate__animated animate__zoomIn" style="animation-delay: 500ms">{{ t('Clarity')}}.</span>
        <span class="animate__animated animate__zoomIn" style="animation-delay: 1s">{{ t('Excellence')}}.</span></h1>
    </div>
    <div class="mouse-outer">
      <div class="button"></div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery'
import { useI18n } from 'vue-i18n'

export default {
  setup() {

    $(document).ready(function () {

      $(window).resize(function () {
        $("#intro").height($(window).height() + "px");
        $("#intro .text_wrapper").css("padding-top",($(window).height()-$("#intro .text_wrapper").height())/2 + "px");
      }).resize();

      $(window).scroll(function () {

        var $intro_text = $('#intro .text_wrapper');
        var $st = $(this).scrollTop();
        var $intro = $("#intro").offset().top;
        if ( $st > $intro ) {
          var _calc = -$st+$intro;
          $intro_text.find("h1").css("font-size",60*(1-(_calc/$intro_text.height()/4)*(-1)) + "px");
          $intro_text.css("opacity",1-(_calc/$intro_text.height()/4)*(-1));
          $(".mouse-outer").css("opacity",1-(_calc/$intro_text.height()/2)*(-1));
        } else {
          $intro_text.css("font-size","60px");
          $intro_text.css("opacity",1);
          $(".mouse-outer").css("opacity",1)
        }
      })

    })

    const { t, locale } = useI18n()

    return { t, locale }

  }
}

</script>

<style lang="css" scoped>
</style>
