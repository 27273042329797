<template>
  <section id="testimonials">
    <div class="container">
      <div class="heading">
        <h2 class="title">{{ t("What our customers say?") }}</h2>
        <div class="mini-separator center pink"></div>
      </div>
      <carousel :items-to-show="1" :autoplay="3000" :wrap-around="true">
        <slide v-for="item in testimonials[locale]" :key="item.client">
          <div class="">
            <div class="text">
              {{ item.content }}
            </div>
            <div class="client">
              {{ item.client }}
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
  name: 'TestimonialsList',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const { t, locale } = useI18n({
      messages: {
        "en": {
          "What our customers say?" : "What our customers say?"
        },
        "si": {
          "What our customers say?" : "Kaj pravijo naše stranke?"
        }
      }
    })

    const testimonials = {
      "en" : [
        {
          client: 'Kreja d.o.o.',
          url: 'https://www.kreja.eu',
          content: 'We especially appreciate the extremely fast respons from the Vorkum team! Our needs and wishes are heard, and the work is done in really fast time. We have been cooperating for many years and we are delighted to recommend you VORKUM.'
        }, {
          client: 'Popikoki',
          url: 'https://www.popikoki.si',
          content: '100% satisfied! Friendly and fast, with excellent support. Personal approach and professionalism come first. Thank you!'
        }, {
          client: 'CenterKontura',
          url: 'https://www.centerkontura.si',
          content: 'When we choose business partners, the most important thing to us is fast respons when the problems arise. Professional attitude and fast respons is what convinced us that VORKUM is our best business partner when it comes to solving all "website" challenges.'
        }
      ],
      "si": [
        {
          client: 'Kreja d.o.o.',
          url: 'https://www.kreja.eu',
          content: 'Pri ekipi vorkum cenimo predvsem izjemno hitro odzivnost! Našim potrebam in željam prisluhnejo in delo opravijo v zares hitrem času. Naše sodelovanje traja že več let in lahko vam ga priporočamo.'
        }, {
          client: 'Popikoki',
          url: 'https://www.popikoki.si',
          content: '100% zadovoljni! Prijazni in hitri z odlično podporo.  Oseben pristop in strokovnost sta na prvem mestu. Hvala!'
        }, {
          client: 'CenterKontura',
          url: 'https://www.centerkontura.si',
          content: 'Ko izbiramo poslovne partnerje nam je najbolj pomembno, da se na nastale težave hitro odzivajo. Profesionalen in strokoven odnos ter hiter odziv je nekaj kar nas vedno prepriča, da je VORKUM naš najboljši poslovni partner pri reševanju vseh "spletnih" izzivov.'
        },
        {
          client: 'Perunika',
          url: 'https://www.perunika.org',
          content: 'Kot spletna trgovina se moramo vsakodnevno prilagajati novim izzivom, kar nam brez Vorkuma nedvomno ne bi uspelo. Ekipa nam je v izjemno pomoč pri iskanju kreativnih rešitev. So neverjetno odzivni in hitri, 10/10, toplo priporočamo!'
        }
      ]
    };

    return {
      t,
      locale,
      testimonials
    }
  }
}

</script>

<style lang="scss">

</style>
