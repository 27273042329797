export default {
  "Simplicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enostavnost"])},
  "Clarity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiselnost"])},
  "Excellence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odličnost"])},
  "We are experts in...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smo strokovnjaki na področju..."])},
  "What we do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storitve"])},
  "Case studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerji"])},
  "Testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnenja"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "website development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izdelave spletih strani"])},
  "ecommerce website development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izdelave spletnih trgovin"])},
  "extending and adapting opensource software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prilagajanja odprtokodnih spletnih aplikacij in rešitev"])},
  "building advanced web and mobile applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["načrtovanja in razvoja naprednih in kompleksnih spletnih ter mobilnih aplikacij"])},
  "website and webstore maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vzdrževanja spletnih strani in spletnih trgovin"])},
  "Facebook apps development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izdelave Facebook aplikacij"])},
  "Good cooperation guarantees a good product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobro sodelovanje vodi v dober produkt."])},
  "We listen to our clients.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imamo posluh za stranke."])},
  "Tell us your wishes, the purpose of the website and what you want to achieve. It is our job to listen and shape your goals into a design that heightens the meaning by the content.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaupajte nam, kaj želite s spletno stranjo doseči in kakšna so vaša pričakovanja. Naša naloga je, da poslušamo in te želje oblikujemo v formo, ki bo z vsebino dobila smisel."])},
  "Good, fast or low-cost. Choose two.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimalna rešitev naj bo tudi kakovostna."])},
  "A project triangle, identified by volume, time and costs, can help us find the best solution. We strive for a quality, optimum solution, and a fast completion.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektni trikotnik, ki ga tvorijo obseg, čas in stroški, nam lahko pomaga pri iskanju optimalne rešitve. Prizadevamo si, da je optimalna rešitev tudi kakovostna in da je ažurno pripravljena."])},
  "Honesty reflects respect for clients.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskrenost je odraz spoštovanja do strank."])},
  "Mutual respect and honesty are also important for good cooperation. We are honest to all our clients, about both the work and our cooperation. It is a reflection of our respect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za dobro sodelovanje sta pomembna tudi medsebojno spoštovanje in iskrenost.  Do vseh svojih strank smo iskreni, tako glede dela kot sodelovanja. To je naš odraz spoštovanja."])},
  "Our partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerji"])},
  "Tools and tehnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri našem delu uporabljamo..."])},
  "VAT number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID za DDV"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "Registration number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matična številka"])},
  "All rights reserved.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vse pravice pridržane."])},
  "Vorkum | Web development": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vorkum"]), _normalize(["Načrtovanje in razvoj spletnih aplikacij"])])},
  "Advanced web development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izdelava spletnih strani, spletnih trgovin ter načrtovanje in razvoj naprednih spletnih aplikacij"])}
}