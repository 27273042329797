export default {
  "Simplicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplicity"])},
  "Clarity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clarity"])},
  "Excellence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellence"])},
  "We are experts in...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experts in..."])},
  "What we do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we do"])},
  "Case studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case studies"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "website development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["website development"])},
  "ecommerce website development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ecommerce website development"])},
  "extending and adapting opensource software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extending and adapting opensource software"])},
  "building advanced web and mobile applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building advanced web and mobile applications"])},
  "website and webstore maintanance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["website and webstore maintanance"])},
  "Facebook apps development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook apps development"])},
  "Good cooperation guarantees a good product.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good cooperation guarantees a good product."])},
  "We listen to our clients.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We listen to our clients."])},
  "Tell us your wishes, the purpose of the website and what you want to achieve. It is our job to listen and shape your goals into a design that heightens the meaning by the content.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us your wishes, the purpose of the website and what you want to achieve. It is our job to listen and shape your goals into a design that heightens the meaning by the content."])},
  "Good, fast or low-cost. Choose two.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good, fast or low-cost. Choose two."])},
  "A project triangle, identified by volume, time and costs, can help us find the best solution. We strive for a quality, optimum solution, and a fast completion.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A project triangle, identified by volume, time and costs, can help us find the best solution. We strive for a quality, optimum solution, and a fast completion."])},
  "Honesty reflects respect for clients.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honesty reflects respect for clients."])},
  "Mutual respect and honesty are also important for good cooperation. We are honest to all our clients, about both the work and our cooperation. It is a reflection of our respect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutual respect and honesty are also important for good cooperation. We are honest to all our clients, about both the work and our cooperation. It is a reflection of our respect."])},
  "Our partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our partners"])},
  "Tools and tehnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools and tehnologies"])},
  "VAT number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Registration number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
  "All rights reserved.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All right reserved."])},
  "Vorkum | Web development": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vorkum"]), _normalize(["Web development"])])},
  "Advanced web development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced web development"])}
}